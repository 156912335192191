const host = window.location.hostname;

const configs = {
  localhost: {
    apiUrl: "http://localhost:5540/",
    authAppUrl: "http://localhost:9292",
    assetsUrl: "http://localhost:5003",
    authHostsPattern: "localhost",
    name: "development",
  },
  "assets.quiteja.staging.gorillascode.com": {
    apiUrl: "https://api.assets.quiteja.staging.gorillascode.com/",
    assetsUrl: "https://assets.quiteja.staging.gorillascode.com",
    authAppUrl: "https://auth.staging.gorillascode.com",
    authHostsPattern: "quiteja.staging.gorillascode.com",
    name: "staging",
  },
  "assets.quiteja.com.br": {
    apiUrl: "https://assets.quiteja.com.br/api/",
    assetsUrl: "https://assets.quiteja.com.br",
    authAppUrl: "https://auth.quiteja.com.br",
    authHostsPattern: "assets.quiteja.com.br",
    name: "production",
  },
};

export default {
  apiUrl: configs[host].apiUrl,
  authAppUrl: configs[host].authAppUrl,
  assetsUrl: configs[host].assetsUrl,
  authHostsPattern: configs[host].authHostsPattern,
  name: configs[host].name,
};
